<ng-progress></ng-progress>
<router-outlet #pnl ></router-outlet>
<p-toast position="bottom-right"></p-toast>

<p-toast position="center" key="custom-message" [modal]="true" [baseZIndex]="12001" class="custom-message">
    <p-blockUI blocked="true" [baseZIndex]="10001" [target]="pnl"></p-blockUI>
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <img src="/assets/layout/images/topbar/success.png" alt="" height="45px" width="45px" *ngIf="message.severity === 'success'">
            <img src="/assets/layout/images/topbar/Info.png" alt="" height="45px" width="45px" *ngIf="message.severity !== 'success'">
            <p>{{message.detail}}</p>
        </div>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 text-center" style="text-align: center;">
                <button type="button" pButton label="OK" (click)="onConfirm()" class="btn bg-primary" style="width: 120px;"></button>
            </div>
        </div>
    </ng-template>
</p-toast>