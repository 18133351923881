<div class="layout-topbar">
        <div class="layout-topbar-wrapper p-col-12 p-md-12 p-0">
            <div class="layout-topbar-left p-md-7">
                <div class="main-menu p-col-1">
                    <!-- <a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)">
                        <img src="/assets/layout/images/topbar/menu.png">
                    </a> -->
                    <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="app.onToggleMenuClick($event) && app.onMenuButtonClick($event)">
                        <img src="/assets/layout/images/topbar/menu.png">
                    </a>
                </div>
            </div>
            <div class="layout-topbar-center p-md-3">
               <div class="p-inputgroup">
                    <span class="p-inputgroup-addon bg-transparent"><img src="assets/layout/images/topbar/company.png" alt="" srcset=""> </span>
                    <p-dropdown [options]="buildings" [(ngModel)]="selectedBuilding" class="dp-ms dp-bg-white w-full dp-border" placeholder={{buildingName}} filter="false"></p-dropdown>
                </div>
            </div>
            <div class="layout-topbar-right fadeInDown p-md-1 p-col-12">
                <ul class="layout-topbar-actions">
                    <li #profile class="topbar-item profile-item"
                        [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
                        <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                            <span class="profile-image-wrapper">
                                <img src="assets/layout/images/topbar/avatar.svg" alt="mirage-layout" />
                                <!-- <div id="profileImage">{{avatar}}</div>     -->    
                            </span>
                            <span class="profile-info-wrapper">
                            </span>
                        </a>
                        <ul class="profile-item-submenu fadeInDown">
                            <li class="profile-submenu-header" style="height: 55px;">
                                <div class="profileAvatar">
                                    <div id="profileImageCheck">{{avatar}}</div>
                                </div>
                                <div class="profile">
                                    <!-- <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout"
                                        width="56" /> -->
                                    <h1>{{fullName}}</h1>
                                </div>
                            </li>
                            <!-- <li class="layout-submenu-item" (click)="personalInformation()">
                                <img src="assets/layout/images/topbar/customer_info.png" alt="mirage-layout" width="20" />
                                <div class="menu-text">
                                    <p>Thông tin cá nhân</p>
                                </div>
                            </li>
                            <li class="layout-submenu-item">
                                <img src="assets/layout/images/topbar/add_address.png" alt="mirage-layout" width="20" />
                                <div class="menu-text">
                                    <p>Địa chỉ lấy hàng</p>
                                </div>
                            </li> -->
                            <li class="layout-submenu-item"  (click)="changePassword()">
                                <img src="assets/layout/images/topbar/change_password.png" alt="mirage-layout" width="20" />
                                <div class="menu-text">
                                    <p>Đổi mật khẩu</p>
                                </div>
                            </li>
                            <li class="layout-submenu-item" (click)="logout()">
                                <img src="assets/layout/images/topbar/logout.png" alt="mirage-layout" width="20" />
                                <div class="menu-text" style="cursor: pointer !important;">
                                    <p>Đăng xuất</p>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="profile-mobile-wrapper">
                    <li #mobileProfile class="topbar-item profile-item"
                        [ngClass]="{'active-topmenuitem': app.activeTopbarItem === mobileProfile}">
                        <a href="#" (click)="app.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                                <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout" />
                            </span>
                            <span class="profile-info-wrapper">
                            </span>
                        </a>
                        <ul class="fadeInDown">
                            <li class="profile-submenu-header">
                                <div class="profile">
                                    <img src="assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout"
                                        width="56" />
                                    <h1>user?.fullName</h1>
                                </div>
                            </li>
                            <li class="layout-submenu-item">
                                <img src="assets/layout/images/topbar/customer_info.png" alt="mirage-layout" width="20" />
                                <div class="menu-text">
                                    <p>Thông tin cá nhân</p>
                                </div>
                            </li>
                            <li class="layout-submenu-item">
                                <img src="assets/layout/images/topbar/add_address.png" alt="mirage-layout" width="20" />
                                <div class="menu-text">
                                    <p>Địa chỉ lấy hàng</p>
                                </div>
                            </li>
                            <li class="layout-submenu-footer">
                                <button class="signout-button" (click)="logout()">Đăng xuất</button>
                                <button class="buy-mirage-button">Đổi mật khẩu</button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
</div>