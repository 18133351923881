<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}" (click)="app.onSidebarClick($event)"
     (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
    <div class="sidebar-logo logo-lg">
        <a [routerLink]="['/']">
            <img alt="logo" src="assets/layout/images/dipvietnam.png"/>
        </a>
    </div>
    <div class="sidebar-logo logo-sm">
        <a [routerLink]="['/']">
            <img alt="logo" src="assets/layout/images/icon.svg"/>
        </a>
    </div>

    <div class="layout-menu-container" (click)="onMenuClick()">
        <ul class="layout-menu">
            <ng-template ngFor let-item let-i="index" [ngForOf]="model">
                <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
            </ng-template>
            <!-- <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li> -->
        </ul>
    </div>
</div>
