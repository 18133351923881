<body class="gray-bg">
    <div class="middle-box text-center animated fadeInDown">
        <h1>403</h1>
        <div class="error-desc">
            Bạn không có quyền sử dụng tính năng này, 
            <br/>vui lòng liên hệ với bộ phận IT để biết thêm chi tiết 
            <br/><br/>
            <a href="">Trở lại trang chủ</a>
            <br/>
            <a (click)="logout()">Đăng xuất</a>
        </div>
    </div>

</body>