<ng-container>
    <a [id]="item.routerLink" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items" (mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)"
      [attr.target]="item.target" [attr.tabindex]="0">
        <span class="menuitem-text">{{item.label}}</span>
        <i style="color: black !important;" class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
        <!-- <i [ngClass]="item.icon"></i> -->
        <img [src]="item.img" *ngIf="!active && item.img && item.imgActive">
        <!-- <img [src]="item.imgActive" *ngIf="active && item.imgActive"> -->
        <img [src]="item.imgActive"  *ngIf="item.imgActive">
    </a>
    <a (click)="itemClick($event)" (mouseenter)="onMouseEnter()" *ngIf="item.routerLink && !item.items"
        [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
        [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
        <img [src]="item.img" *ngIf="!active && item.img">
        <img [src]="item.imgActive" *ngIf="active && item.imgActive">
        <span class="menuitem-text" *ngIf="item.isChildren">{{item.label}}</span>
        <span class="menuitem-text" *ngIf="!item.isChildren" style="padding-left: 25px;">{{item.label}}</span>
        <!-- <i [ngClass]="item.icon"></i> -->
    </a>
    <ul *ngIf="item.items && active" [@children]="(app.isHorizontal() && root) ? (active ? 'visible' : 'hidden') : (active ? 'visibleAnimated' : 'hiddenAnimated')">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
            <img [src]="item.img" *ngIf="item.items && !active">
        </ng-template>
    </ul>
</ng-container>