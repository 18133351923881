import { EntityBasic } from './EntityBasic.model';
import { Role } from './role.model';

export class User extends EntityBasic {
    password: string;
    departmentId: number;
    hubId: number;
    userName: string;
    email: string;
    phoneNumber: string;
    address: string;
    avatarPath: string;
    isGlobalAdministrator: boolean;
    isHidden: boolean;
    fullName: string;
    accessFailedCount: number;
    emailConfirmed: string;
    lockoutEnabled: boolean;
    lockoutEndDateUtc: any;
    lockoutEnd: any;
    normalizedEmail: string;
    normalizedUserName: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    identityCard: string;
    currentLat: number;
    currentLng: number;
    lastUpdateLocationTime: any;
    isPassWordBasic: boolean;
    expiresDate: any;
    blockTime: number;
    seriNumber: string;
    isBlocked: boolean;
    x: number;
    y: number;
    z: number;
    oldLat: number;
    oldLng: number;
    manageHubId: number;
    companyId: number;
    typeUserId: number;
    customerId: number;
    role:Role;
    roleIds: any;
    roles:any;
    userRoleIds: any;
    roleId: number;
    // hrm_ProfileCode: number;
    // hrm_Workplace: number;
    // hrm_PayRate: number;
    // hrm_PayRangesId: number;
    // hrm_DateStartWork: number;
    // hrm_DateEndProbation: number;
    // hrm_RecruitmentMethodId: number;
    // hrm_EmployeeIsReplacedId: number;
    // hrm_Birthday: number;
    // hrm_Gender: number;
    // hrm_Married: number;
    // hrm_NationId: number;
    // hrm_ReligionId: number;
    // hrm_HouseholdRegisterFamilyNumber: number;
    // hrm_RelationshipToTheHouseholder: number;
    // hrm_EmergencyContactNumber: number;
    // hrm_EmergencyContactName: number;
    // hrm_EmergencyContactRelationship: number;
    // hrm_IdentityCardOrPassport: number;
    // hrm_IdentityCardOrPassportPlaceOfIssuance: number;
    // hrm_IdentityCardOrPassportDate: number;
    // hrm_BirthProvinceId: number;
    // hrm_BirthDistrictId: number;
    // hrm_BirthWardId: number;
    // hrm_PermanentAddress: number;
    // hrm_PermanentWardId: number;
    // hrm_PermanentDistrictId: number;
    // hrm_PermanentProvinceId: number;
    // hrm_ContactAddress: number;
    // hrm_ContactWardId: number;
    // hrm_ContactDistrictId: number;
    // hrm_ContactProvinceId: number;
    // hrm_TrainingFacilities: number;
    // hrm_TrainingTime: number;
    // hrm_DegreeId: number;
    // hrm_Specialized: number;
    // hrm_GraduationYear: number;
    // hrm_GradeId: number;
    // hrm_PersonalIncomeTax: number;
    // hrm_Email: number;
    // hrm_PhoneNumber: number;
    // hrm_AtmCardNumber: number;
    // hrm_BankName: number;
    // hrm_BankBranch: number;
    // hrm_BankProvinceId: number;
    // hrm_CommitIncome: number;
    // hrm_RelatedPersonNumber: number;
    // hrm_TaxableIncome: number;
    // hrm_Maternity: number;
    // hrm_Guarantee: number;
    // hrm_KeepOriginalProfile: number;
    // hrm_SeniorityMonth: number;
    // hrm_SeniorityYear: number;
    // hrm_LeaveOfAbsenceNumber: number;
    // hrm_LeaveDate: number;
    // hrm_DepositMoney: number;
    // hrm_DepositTime: number;
    // hrm_ReceiptsNumber: number;
    // hrm_DepositMonthly: number;
    // hrm_TwentyFourHourInsuranceStartDate: number;
    // hrm_TwentyFourHourInsuranceEndDate: number;
    // hrm_TwentyFourHourInsurancePremium: number;
    // hrm_CarInsuranceDateStartDate: number;
    // hrm_CarInsuranceDateEndDate: number;
    // hrm_CarInsuranceDatePremium: number;
    // hrm_Note: number;
    // hrm_FileUpload: number;
    // hrm_FileNameUpload: number;
    // hrm_FileCV: number;
    // hrm_FileResume: number;
    // hrm_FileIdentityCard: number;
    // hrm_FileHouseholdRegisterFamily: number;
    // hrm_FileBirthCertificate: number;
    // hrm_FileHealthCertification: number;
    // hrm_FilePhoto: number;
    // hrm_FileInformaticsCertificate: number;
    // hrm_FileLanguageCertificate: number;
    // hrm_FileEmployeeInformation: number;
    // hrm_FileGuarantee: number;
    // hrm_FilePrivacyStatement: number;
    // hrm_FileVPBCard: number;
    // hrm_FileConfidentialDeposit: number;
    // hrm_FileDriver: number;
    // hrm_FileMaternity: number;
    // hrm_FileProbationaryContract: number;
    // hrm_FileLabourContract: number;
    // hrm_FileDecision: number;
}
