import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SortUtil } from '../../infrastructure/sort.util';
import { ResponseModel } from '../../models/entity/response.model';
import { SelectModel } from '../../models/entity/Selected.model';
import { MsgService } from '../local/msg.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root',
})
export class BuildingService extends GeneralService {
  constructor(
    protected messageService: MsgService,
    protected httpClient: HttpClient
  ) {
    super(messageService, httpClient, environment.opapiUrl, 'Building');
  }

  async getBuilding(viewModel: any): Promise<ResponseModel> {
    const res = await this.postCustomApi('GetBuilding', viewModel);
    if (!this.isValidResponse(res)) {
      return;
    }
    return res;
  }

  async getFilledAreaStatistic(viewModel: any): Promise<ResponseModel> {
    const res = await this.postCustomApi('GetFilledAreaStatistic', viewModel);
    if (!this.isValidResponse(res)) {
      return;
    }
    return res;
  }

  async getBuildingAsync(viewModel: any): Promise<SelectModel[]> {
    const res = await this.getBuilding(viewModel);
    if (res.isSuccess) {
      let selectModel = [];
      let datas = res.data as any[];

      datas = SortUtil.sortAlphanumerical(datas, 1, 'code');
      datas.forEach((element) => {
        selectModel.push({
          label: `${element.tenVT}`,
          data: element,
          value: element.maTN,
        });
      });
      selectModel.unshift({
        label: '-- Chọn dữ liệu --',
        data: null,
        value: null,
      });
      return selectModel;
    }
  }

  async getBuildingByUserId(viewModel: any): Promise<ResponseModel> {
    const res = await this.postCustomApi('GetBuildingByUserId', viewModel);
    if (!this.isValidResponse(res)) {
      return;
    }
    return res;
  }

  async getBuildingByUserIdAsync(viewModel: any): Promise<SelectModel[]> {
    const res = await this.getBuildingByUserId(viewModel);
    if (res.isSuccess) {
      let selectModel = [];
      let datas = res.data as any[];

      datas = SortUtil.sortAlphanumerical(datas, 1, 'code');
      datas.forEach((element) => {
        selectModel.push({
          label: `${element.tenVT}`,
          data: element,
          value: element.maTN,
        });
      });
      selectModel.unshift({
        label: '-- Chọn dữ liệu --',
        data: null,
        value: null,
      });
      return selectModel;
    }
  }
}
