<section>
    <div class="p-grid form-group">
        <div class="p-md-12 p-col-12">
            <label>Mật khẩu hiện tại</label>
            <input [type]="inputElementNewPassword.type" [(ngModel)]="changePasswordModel.currentPassWord" pInputText class="input search-input-wrapper" placeholder="Nhập mật khẩu hiện tại..."/>
            <img [src]="inputElementNewPassword.src" (click)="onClickNewPassword()">
        </div>
    </div>
    <div class="p-grid form-group">
        <div class="p-md-12 p-col-12">
            <label>Mật khẩu mới</label>
            <input [type]="inputElementConfirmPassword.type" [(ngModel)]="changePasswordModel.newPassWord" pInputText class="input search-input-wrapper" placeholder="Nhập mật khẩu mới..."/>
            <img [src]="inputElementConfirmPassword.src" (click)="onClickConfirmPassword()">
        </div>
    </div>
    <div class="p-grid form-group">
        <div class="p-md-12 p-col-12">
            <label>Xác nhận mật khẩu mới</label>
            <input [type]="inputElementConfirmChangePass.type" [(ngModel)]="changePass" pInputText class="input search-input-wrapper" placeholder="Nhập lại mật khẩu mới..."/>
            <img [src]="inputElementConfirmChangePass.src" (click)="onClickConfirmChangePass()">
        </div>
    </div>
    <div class="p-grid form-group">
        <div class="p-md-2 p-col-12">
        </div>
        <div class="p-md-4 p-col-12">
            <button pButton type="button" (click)="chanPassword()" label="LƯU" class="btn bg-primary color-black br-none"></button>
        </div>
        <div class="p-md-4 p-col-12">
            <button pButton style="color: #000 !important;" type="button" (click)="closeDialog()" label="HUỶ BỎ" class="btn bg-cancel color-black br-none"> </button>
        </div>
        <div class="p-md-2 p-col-12">
        </div>
    </div>

</section>