<div class="layout-footer" >
    <div class="logo-text">
        <div class="text">
            <h1 class="fs-12"><b class="color-primary">2002 - 2022 @ DIP Vietnam. ALL Rights Reserved.</b></h1>
        </div>
    </div>
    <div class="icons">
        <div class="text">
            <h1 class="fs-12">Verion 1.0.1</h1>
        </div>
    </div> 
</div>