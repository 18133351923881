import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSecuredComponent } from 'src/app/app-secured/app-secured.component';
import { environment } from 'src/environments/environment';
import { Page } from '../../models/entity/page.model';
import { PageService } from '../../services/api/page.service';
import { PermissionService } from '../../services/api/permission.service';
import { MsgService } from '../../services/local/msg.service';
import { BaseComponent } from '../baseComponent';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styles: [
    ]
})
export class MenuComponent extends BaseComponent implements OnInit {

    model: any[] = [];
    envir = environment;
    pages: Page[];


    constructor(
        public app: AppSecuredComponent,
        protected pageService: PageService,
        protected msgService: MsgService,
        protected router: Router,
        protected permissionService: PermissionService,  
    ) {
        super(msgService,router, permissionService);
     }

    async ngOnInit(): Promise<any> {
        // this.pages = (await this.pageService.getMenuByModuleIdAsync(1)).data;
        // this.pages.forEach(page => {
        //     if (!page.parentPageId) {
        //         const menu = { label: page.name, icon: page.icon, routerLink: [page.aliasPath],
        //             img: 'assets/layout/images/topbar/dashboard.png',
        //             imgActive: 'assets/layout/images/topbar/dashboard_active.png',items: [] };
        //         menu.items = this.pages.filter(x => x.parentPageId === page.id).map(child => {
        //             return { 
        //                 label: child.name, icon: child.icon, routerLink: [child.aliasPath],
        //                 img: 'assets/layout/images/topbar/dashboard.png',
        //                 imgActive: 'assets/layout/images/topbar/dashboard_active.png',
        //             };
        //         });
        //         this.model.push(menu);
        //     }
        // });
        // const UserId = this.authService.getUserId();
        // const res = await this.pageService.getMenuByUser();
        // if (res.isSuccess){
        //     const data = res.data as PageModel[];
        //     data.map(x => {
        //         if (x.pageId == null){
        //             x.children = data.filter(y => y.pageId === x.id);
        //             this.model.push(x);
        //         }

        //     });
        // }
        this.model = [
            {
                label: 'Dashboard',
                img: 'assets/layout/images/topbar/dashboard.svg',
                imgActive: 'assets/layout/images/topbar/dashboard_active.svg',
                routerLink: ['/']
            },    
            {
                label: 'Quản lý hệ thống',
                imgActive: 'assets/layout/images/topbar/system_management_active.svg',
                routerLink: ['/system-management'],
                items: [
                    {
                        label: 'Hệ thống',
                        routerLink: ['/system-management/system'],
                        items: [
                            {
                                label: 'Quản lý ngôn ngữ',
                                routerLink: ['/system-management/system/language']
                            },
                            {
                                label: 'Quản lý mã',
                                routerLink: ['/system-management/system/code-system']
                            }
                        ]
                    },
                    {
                        label: 'Quản lý dự án',
                        routerLink: ['/system-management/project-management'],
                        isChildren: true
                    },
                    {
                        label: 'Quản lý tài liệu',
                        routerLink: ['/system-management/doc-management'],
                        items: [
                            {
                                label: 'Nhóm biểu mẫu',
                                routerLink: ['/system-management/doc-management/group-form']
                            },
                            {
                                label: 'Trường trộn',
                                routerLink: ['/system-management/doc-management/mixing-field']
                            },
                            {
                                label: 'Tài liệu',
                                routerLink: ['/system-management/doc-management/doc']
                            },
                            {
                                label: 'Template',
                                routerLink: ['/system-management/doc-management/template']
                            }
                        ]
                    },
                    // {
                    //     label: 'Quản lý mẫu in báo cáo',
                    //     routerLink: ['/system-management/report-form'],
                    //     isChildren: true
                    // }
                ]
            },
            {
                label: 'Quản lý Dự án',
                img: 'assets/layout/images/topbar/project_management.svg',
                imgActive: 'assets/layout/images/topbar/project_management_active.svg',
                routerLink: ['/project-management'],
                items: [
                    {
                        label: 'Danh mục chung',
                        routerLink: ['/project-management/general-directory'],
                        items: [
                            {
                                label: 'Block',
                                routerLink: ['/project-management/general-directory/block']
                            },
                            {
                                label: 'Floor',
                                routerLink: ['/project-management/general-directory/floor']
                            },
                            {
                                label: 'Space type',
                                routerLink: ['/project-management/general-directory/space-type']
                            },
                            {
                                label: 'Nhóm mặt bằng',
                                routerLink: ['/project-management/general-directory/group-ground']
                            },
                            {
                                label: 'Status Apartment',
                                routerLink: ['/project-management/general-directory/status-apartment']
                            },
                            {
                                label: 'Loại Giá thuế',
                                routerLink: ['/project-management/general-directory/tax-rate-type']
                            },
                            {
                                label: 'Loại tiền',
                                routerLink: ['/project-management/general-directory/type-of-money-management']
                            },
                            {
                                label: 'Đơn vị tính',
                                routerLink: ['/project-management/general-directory/unit-management']
                            },
                            {
                                label: 'Zone',
                                routerLink: ['/project-management/general-directory/zone']
                            },
                            {
                                label: 'Supplier',
                                routerLink: ['/project-management/general-directory/supplier']
                            },
                            {
                                label: 'department',
                                routerLink: ['/project-management/general-directory/department']
                            },
                            {
                                label: 'Position',
                                routerLink: ['/project-management/general-directory/position']
                            },
                            {
                                label: 'Tài khoản ngân hàng',
                                routerLink: ['/project-management/general-directory/account-bank']
                            },
                            {
                                label: 'Bank',
                                routerLink: ['/project-management/general-directory/bank']
                            },
                            {
                                label: 'Nationality',
                                routerLink: ['/project-management/general-directory/nationality']
                            },
                            {
                                label: 'Loại yêu cầu',
                                routerLink: ['/project-management/general-directory/requet-type']
                            }
                        ]
                    },
                    {
                        label: 'Quản lý nhân viên',
                        routerLink: ['/project-management/staff-management'],
                        items: [
                            {
                                label: 'Quản lý nhân viên',
                                routerLink: ['/project-management/staff-management/staff']
                            },
                            // {
                            //     label: 'Quản lý module',
                            //     routerLink: ['/project-management/staff-management/module']
                            // },
                            // {
                            //     label: 'Quản lý form',
                            //     routerLink: ['/project-management/staff-management/form']
                            // },
                            // {
                            //     label: 'Quản lý user group',
                            //     routerLink: ['/project-management/staff-management/user-group']
                            // },
                            // {
                            //     label: 'Quản lý Decentralization',
                            //     routerLink: ['/project-management/staff-management/decentralization']
                            // },
                            {
                                label: 'Phân quyền báo cáo',
                                routerLink: ['/project-management/staff-management/permission-to-report']
                            },
                        ]
                    },
                    {
                        label: 'Quản lý Apartment',
                        routerLink: ['/project-management/apartment-management'],
                        items: [
                            {
                                label: 'Quản lý căn hộ',
                                routerLink: ['/project-management/apartment-management/apartment-item']
                            },
                            // {
                            //     label: 'Phân nhóm căn hộ',
                            //     routerLink: ['/project-management/apartment-management/apartment-group']
                            // },
                            {
                                label: 'Thống kê diện tích lấp đầy',
                                routerLink: ['/project-management/apartment-management/filled-area-statistics']
                            },
                        ]
                    },
                    {
                        label: 'Quản lý kinh phí dự trù',
                        routerLink: ['/project-management/budget-management'],
                        items: [
                            {
                                label: 'Kinh phí cả năm',
                                routerLink: ['/project-management/budget-management/full-price-year']
                            },
                            {
                                label: 'Kinh phí hạng mục',
                                routerLink: ['/project-management/budget-management/item-price-year']
                            }
                        ]
                    }
                ]
            },
            {
                label: 'Quản lý Khách hàng',
                img: 'assets/layout/images/topbar/customer_management.svg',
                imgActive: 'assets/layout/images/topbar/customer_management.svg',
                routerLink: ['/customer-management'],
                items: [
                    {
                        label: 'Danh mục chung',
                        routerLink: ['/customer-management/general-directory'],
                        items: [
                            {
                                label: 'Loại khách hàng',
                                routerLink: ['/customer-management/general-directory/customer-type']
                            },
                            {
                                label: 'Nhóm khách hàng',
                                routerLink: ['/customer-management/general-directory/customer-group']
                            },
                            {
                                label: 'Mức độ yêu cầu',
                                routerLink: ['/customer-management/general-directory/required-level']
                            },
                            {
                                label: 'Trạng thái yêu cầu',
                                routerLink: ['/customer-management/general-directory/require-status']
                            },
                            {
                                label: 'Trạng thái cư dân',
                                routerLink: ['/customer-management/general-directory/resident-status']
                            },
                            {
                                label: 'Mức độ cư dân',
                                routerLink: ['/customer-management/general-directory/resident-level']
                            },
                            {
                                label: 'Trạng thí lễ tân',
                                routerLink: ['/customer-management/general-directory/receptionist-status']
                            },
                            {
                                label: 'Cài đặt',
                                routerLink: ['/customer-management/general-directory/setting']
                            },
                            {
                                label: 'Nhóm công việc',
                                routerLink: ['/customer-management/general-directory/group-work']
                            },
                            // {
                            //     label: 'Cấu hình ngày nghĩ',
                            //     routerLink: ['/customer-management/general-directory/configuring-day-off']
                            // },
                            // {
                            //     label: 'Cấu hình ngày nghĩ theo toàn nhà',
                            //     routerLink: ['/customer-management/general-directory/configuring-day-off-by-building']
                            // },
                            {
                                label: 'Quan hệ',
                                routerLink: ['/customer-management/general-directory/relationship']
                            }
                        ]
                    },
                    {
                        label: 'Quản lý khách hàng',
                        routerLink: ['/customer-management/customer'],
                        isChildren: true
                    },
                    {
                        label: 'Quản lý cư dân',
                        routerLink: ['/customer-management/resident'],
                        isChildren: true
                    },
                    {
                        label: 'Quản lý yêu cầu',
                        routerLink: ['/customer-management/management-requet'],
                        items: [
                            {
                                label: 'Cài đặt gửi mail',
                                routerLink: ['/customer-management/management-requet/mail-setting']
                            },
                            {
                                label: 'Danh sách yêu cầu',
                                routerLink: ['/customer-management/management-requet/require']
                            },
                            {
                                label: 'Công việc nhân viên',
                                routerLink: ['/customer-management/management-requet/staff-work']
                            }
                        ]
                    },
                    {
                        label: 'Quản lý Lễ tân',
                        routerLink: ['/customer-management/receptionist'],
                        isChildren: true
                    },
                ]
            },
            {
                label: 'Hợp đồng',
                img: 'assets/layout/images/topbar/contract.svg',
                imgActive: 'assets/layout/images/topbar/contract_active.svg',
                routerLink: ['/contract'],
                items: [
                    {
                        label: 'Hợp đồng thuê',
                        routerLink: ['/contract/rental-contract'],
                        items: [
                            {
                                label: 'Hợp đồng cho thuê',
                                routerLink: ['/contract/rental-contract/leases']
                            },
                            {
                                label: 'Mặt bằng đang thuê',
                                routerLink: ['/contract/rental-contract/premises-are-rented']
                            },
                            {
                                label: 'Lịch thanh toán cho thuê',
                                routerLink: ['/contract/rental-contract/rental-payment-schedule']
                            },
                            {
                                label: 'Hợp đồng gần hết hạn',
                                routerLink: ['/contract/rental-contract/contract-almost-expired']
                            },
                            {
                                label: 'Hợp đồng đã hết hạn',
                                routerLink: ['/contract/rental-contract/contract-close']
                            },
                            {
                                label: 'Thanh lý',
                                routerLink: ['/contract/rental-contract/contract-liquidation']
                            }
                        ]
                    },
                    {
                        label: 'Biểu mẫu hợp đồng thuê',
                        routerLink: ['/contract/rental-contract-form'],
                        isChildren: true
                    },
                    {
                        label: 'Trường trộn',
                        routerLink: ['/contract/mixing-field-contract'],
                        isChildren: true
                    },
                    {
                        label: 'Biểu mẫu',
                        routerLink: ['/contract/form'],
                        isChildren: true
                    },
                ]
            },
            {
                label: 'Sổ quỹ',
                img: 'assets/layout/images/topbar/cash_book.svg',
                imgActive: 'assets/layout/images/topbar/cash_book_active.svg',
                routerLink: ['/cash-book'],
                items: [
                    {
                        label: 'Phiếu thu',
                        routerLink: ['/cash-book/receipts'],
                        isChildren: true
                    },
                    {
                        label: 'Phiếu thu đã xóa',
                        routerLink: ['/cash-book/receipts-delete'],
                        isChildren: true
                    },
                    {
                        label: 'Chuyển tiền',
                        routerLink: ['/cash-book/transfer-receipt'],
                        isChildren: true
                    },
                    {
                        label: 'Phiếu chi ký quỹ',
                        routerLink: ['/cash-book/deposit-slip'],
                        isChildren: true
                    },
                    {
                        label: 'Phiếu chi thường',
                        routerLink: ['/cash-book/payment'],
                        isChildren: true
                    },
                    {
                        label: 'Phiếu chi trước',
                        routerLink: ['/cash-book/before-payment'],
                        isChildren: true
                    },
                    {
                        label: 'Phiếu khấu trừ',
                        routerLink: ['/cash-book/deduction-slip'],
                        isChildren: true
                    },
                ]
            },
            {
                label: 'Dịch vụ',
                img: 'assets/layout/images/topbar/service.svg',
                imgActive: 'assets/layout/images/topbar/service_active.svg',
                routerLink: ['/service'],
                items: [
                    {
                        label: 'Danh mục chung',
                        routerLink: ['/service/general-directory'],
                        items: [
                            {
                                label: 'Loại dịch vụ',
                                routerLink: ['/service/general-directory/service-type']
                            },
                            {
                                label: 'Dịch vụ khấu trừ tự động',
                                routerLink: ['/service/general-directory/automatic-deduction-service']
                            },
                            {
                                label: 'Bảng giá dịch vụ cơ bản',
                                routerLink: ['/service/general-directory/basic-service-price-list']
                            },
                            {
                                label: 'Cập nhật bảng giá dịch vụ cơ bản',
                                routerLink: ['/service/general-directory/update-basic-service-price-list']
                            },
                            {
                                label: 'Định mức dữ xe',
                                routerLink: ['/service/general-directory/vehicle-data-rate']
                            },
                            {
                                label: 'Loại xe',
                                routerLink: ['/service/general-directory/range-of-vehicle']
                            },
                            {
                                label: 'Cấu hình API',
                                routerLink: ['/service/general-directory/setting-api']
                            },
                            {
                                label: 'Định mức điện',
                                routerLink: ['/service/general-directory/power-rating']
                            },
                            {
                                label: 'Định mức điện 3 pha',
                                routerLink: ['/service/general-directory/three-phase-power-rating']
                            },
                            {
                                label: 'Định mức điện điều hòa',
                                routerLink: ['/service/general-directory/air-conditioner-power-rating']
                            },
                            {
                                label: 'Định mức nước',
                                routerLink: ['/service/general-directory/water-level']
                            },
                            {
                                label: 'Định mức ưu đãi nước',
                                routerLink: ['/service/general-directory/water-preference-quota']
                            },
                            {
                                label: 'Cách tính nước',
                                routerLink: ['/service/general-directory/calculate-water']
                            },
                            {
                                label: 'Đồng hồ điện chiếu sáng',
                                routerLink: ['/service/general-directory/lighting-electric-meter']
                            },
                            {
                                label: 'Đồng hồ điện điều hòa',
                                routerLink: ['/service/general-directory/air-conditioner-electric-meter']
                            },
                            {
                                label: 'Đồng hồ nước nóng',
                                routerLink: ['/service/general-directory/hot-water-meter']
                            },
                            {
                                label: 'Định mức nước nóng',
                                routerLink: ['/service/general-directory/hot-water-rating']
                            },
                            {
                                label: 'Đồng hồ nước',
                                routerLink: ['/service/general-directory/water-clock']
                            },
                            {
                                label: 'Định mức Gas',
                                routerLink: ['/service/general-directory/gas-rating']
                            },
                            {
                                label: 'Cài đặt chiết khấu',
                                routerLink: ['/service/general-directory/discount-settings']
                            },
                            // {
                            //     label: 'Cài đặt lãi suất nộp chậm',
                            //     routerLink: ['/service/general-directory/set-late-payment-interest-rate']
                            // },
                            {
                                label: 'Cài đặt duyệt hóa đơn',
                                routerLink: ['/service/general-directory/invoice-browsing-settings']
                            },
                            {
                                label: 'Cấu hình format',
                                routerLink: ['/service/general-directory/format-configuration']
                            },
                            // {
                            //     label: 'Nhân viên quản lý nhận mail',
                            //     routerLink: ['/service/general-directory/management-staff-receive-mail']
                            // },
                        ]
                    },
                    {
                        label: 'Dịch vụ cơ bản',
                        routerLink: ['/service/basic-service'],
                        isChildren: true
                    },
                    {
                        label: 'Giữ xem',
                        routerLink: ['/service/keep-watching'],
                        items: [
                            {
                                label: 'Thẻ xe',
                                routerLink: ['/service/keep-watching/car-card']
                            },
                            {
                                label: 'Thẻ xe đã xóa',
                                routerLink: ['/service/keep-watching/deleted-vehicle-card']
                            },
                        ]
                    },
                    {
                        label: 'Điện',
                        routerLink: ['/service/electricity'],
                        items: [
                            {
                                label: 'Điện chiếu sáng',
                                routerLink: ['/service/electricity/electric-lighting']
                            },
                            {
                                label: 'Điện 3 pha',
                                routerLink: ['/service/electricity/three-phase-power']
                            },
                            {
                                label: 'Điện điều hòa',
                                routerLink: ['/service/electricity/electricity']
                            },
                            {
                                label: 'Điện điều hòa ngoài giờ',
                                routerLink: ['/service/electricity/out-of-hours-AC']
                            },
                        ]
                    },
                    {
                        label: 'Nước',
                        routerLink: ['/service/water'],
                        items: [
                            {
                                label: 'Nước',
                                routerLink: ['/service/water/water-item']
                            },
                            {
                                label: 'Nước nóng',
                                routerLink: ['/service/water/hot-water']
                            },
                            {
                                label: 'Nước sinh hoạt',
                                routerLink: ['/service/water/living-Water']
                            }
                        ]
                    },
                    // {
                    //     label: 'Thẻ thang máy',
                    //     routerLink: ['/service/elevator-card'],
                    //     isChildren: true
                    // },
                    {
                        label: 'Hóa đơn',
                        routerLink: ['/service/receipt'],
                        items: [
                            {
                                label: 'Hóa đơn dịch vụ',
                                routerLink: ['/service/receipt/service-invoice'],
                            },
                            {
                                label: 'Hóa đơn dịch vụ đã xóa',
                                routerLink: ['/service/receipt/deleted-service-invoice'],
                            },
                            {
                                label: 'Công nợ tổng hợp theo khách hàng',
                                routerLink: ['/service/receipt/aggregate-debt-by-customer'],
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Báo cáo thông kê',
                img: 'assets/layout/images/topbar/report.svg',
                imgActive: 'assets/layout/images/topbar/report_active.svg',
                routerLink: ['/statistical-report'],
                items: [
                    {
                        label: 'Công nhợ thổng hợp',
                        routerLink: ['/statistical-report/general-liabilities'],
                        items: [
                            {
                                label: 'Báo cáo công nợ cho tất cả',
                                routerLink: ['/statistical-report/general-liabilities/debt-reporting-for-all'],
                            }, 
                            {
                                label: 'Phí phải thu trong tháng',
                                routerLink: ['/statistical-report/general-liabilities/fees-receivable-for-the-month'],
                            }, 
                            {
                                label: 'Báo cáo công nợ',
                                routerLink: ['/statistical-report/general-liabilities/debt-report'],
                            }, 
                            {
                                label: 'Báo cáo công nợ dịch vụ',
                                routerLink: ['/statistical-report/general-liabilities/report-of-service-debt'],
                            }, 
                            {
                                label: 'Báo cáo đã thu',
                                routerLink: ['/statistical-report/general-liabilities/report-received'],
                            }, 
                            {
                                label: 'Báo cáo tổng hợp công nợ dịch vụ',
                                routerLink: ['/statistical-report/general-liabilities/report-of-service-debt'],
                            }, 
                            {
                                label: 'Báo cáo tổng hợp công nợ dịch vụ theo tháng',
                                routerLink: ['/statistical-report/general-liabilities/monthly-service-debt-summary-report'],
                            }, 
                            {
                                label: 'Báo cáo các khoản đã khấu trừ',
                                routerLink: ['/statistical-report/general-liabilities/report-deductibles'],
                            }, 
                            {
                                label: 'Báo cáo công nợ theo tuổi nợ',
                                routerLink: ['/statistical-report/general-liabilities/report-of-debt-by-age-of-debt'],
                            }, 
                            {
                                label: 'Báo cáo tính lãi chậm nộ phí',
                                routerLink: ['/statistical-report/general-liabilities/report-of-late-payment-interest'],
                            }, 
                            {
                                label: 'Báo cáo tổng hợp lãi vay khách hàng',
                                routerLink: ['/statistical-report/general-liabilities/report-of-customer-loan-interest'],
                            },
                        ]
                    },
                    {
                        label: 'Phí quản lý',
                        routerLink: ['/statistical-report/management-fee'],
                        items: [
                            {
                                label: 'Báo cáo chi tiết thu PQL tháng',
                                routerLink: ['/statistical-report/management-fee/Detailed-monthly-PQL-revenue-report'],
                            },
                        ]
                    },
                    {
                        label: 'Điện',
                        routerLink: ['/statistical-report/electricity'],
                        items: [
                            {
                                label: 'Báo cáo dịch vụ điện',
                                routerLink: ['/statistical-report/electricity/electrical-service-report'],
                            },
                            {
                                label: 'Báo cáo điện điều hòa',
                                routerLink: ['/statistical-report/electricity/report-of-AC-power'],
                            },
                            {
                                label: 'Biểu đồ tiêu thụ điện',
                                routerLink: ['/statistical-report/electricity/power-consumption-chart'],
                            },
                            {
                                label: 'Biều đồ tiêu thụ điện 3 pha',
                                routerLink: ['/statistical-report/electricity/electricity-three-chart'],
                            },
                            {
                                label: 'Biểu đồ tiêu thụ điện điều hòa',
                                routerLink: ['/statistical-report/electricity/air-conditioner-power-consumption-chart'],
                            },
                        ]
                    },
                    {
                        label: 'Nước',
                        routerLink: ['/statistical-report/water'],
                        items: [
                            {
                                label: 'Báo cáo dịch nước',
                                routerLink: ['/statistical-report/water/water-translation-report'],
                            },
                            {
                                label: 'Biều đồ tiêu thụ nước',
                                routerLink: ['/statistical-report/water/water-consumption-chart'],
                            },
                            {
                                label: 'Biều đồ tiêu thụ nước nóng',
                                routerLink: ['/statistical-report/water/hot-water-consumption-chart'],
                            },
                            {
                                label: 'Biều đồ tiêu thụ nước sinh hoạt',
                                routerLink: ['/statistical-report/water/domestic-water-consumption-chart'],
                            }
                        ]
                    },
                    {
                        label: 'Gas',
                        routerLink: ['/statistical-report/gas'],
                        items: [
                            {
                                label: 'Báo cáo dịch vụ gas',
                                routerLink: ['/statistical-report/gas/gas-service-report'],
                            },
                            {
                                label: 'Biều đồ tiêu thụ gas',
                                routerLink: ['/statistical-report/gas/gas-consumption-chart'],
                            }
                        ]
                    },
                    {
                        label: 'Xe',
                        routerLink: ['/statistical-report/car'],
                        items: [
                            {
                                label: 'Báo cáo dịch vụ dữ xe',
                                routerLink: ['/statistical-report/car/report-vehicle-data-service'],
                            },
                            {
                                label: 'Báo cáo công nợ thẻ xe',
                                routerLink: ['/statistical-report/car/report-of-car-credit-card-debt'],
                            },
                            {
                                label: 'Báo cáo đã thu thẻ xe phát sinh',
                                routerLink: ['/statistical-report/car/report-of-collected-vehicle-card'],
                            },
                            {
                                label: 'Báo cáo chi tiết phí dữ xe tháng',
                                routerLink: ['/statistical-report/car/detailed-monthly-vehicle-data-report'],
                            },
                            {
                                label: 'Báo cáo doanh thu dữ Oto',
                                routerLink: ['/statistical-report/car/oto-data-sales-report'],
                            },
                            {
                                label: 'Báo cáo doanh thu dữ xe máy',
                                routerLink: ['/statistical-report/car/motorcycle-data-sales-report'],
                            },
                            {
                                label: 'Báo cáo doanh thu dữ xe đap',
                                routerLink: ['/statistical-report/car/bicycle-data-sales-report'],
                            },
                        ]
                    },
                    {
                        label: 'Khác',
                        routerLink: ['/statistical-report/other'],
                        items: [
                            {
                                label: 'Báo cáo hợp đồng thuê mặt bằng',
                                routerLink: ['/statistical-report/other/report-of-premises-lease'],
                            },
                            {
                                label: 'Kế hoạch thu tiền cho thuê',
                                routerLink: ['/statistical-report/other/rental-collection-plan'],
                            },
                            {
                                label: 'Công nợ tiền đặt cọc',
                                routerLink: ['/statistical-report/other/deposit-liability'],
                            },
                            {
                                label: 'Hợp đồng cho thuê',
                                routerLink: ['/statistical-report/other/lease-agreement'],
                            },
                        ]
                    },
                    {
                        label: 'Dự tính',
                        routerLink: ['/statistical-report/expected'],
                        items: [
                            {
                                label: 'BC dự tính doanh thu hợp đồng thuê',
                                routerLink: ['/statistical-report/expected/bc-forecasts-lease-revenue'],
                            },
                            {
                                label: 'BC dự tính doanh thu phí quản lý',
                                routerLink: ['/statistical-report/expected/bc-estimates-management-fee-revenue'],
                            },
                            {
                                label: 'BC dự tính doanh thu xe',
                                routerLink: ['/statistical-report/expected/bc-car-sales-forecast'],
                            },
                        ]
                    },
                ]
            },
        ];
    }

    onMenuClick(): void {
        this.app.menuClick = true;
    }
}
